import { ProfileTab } from './schema';

export const PROFILE_TABS: { [key in ProfileTab]: ProfileTab } = {
  attributes: 'attributes',
  onboard: 'onboard',
  compliance: 'compliance',
  messages: 'messages',
  pulse: 'pulse',
  workerHistories: 'workerHistories',
};
